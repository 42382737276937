@import "../../assets/styles/variables.scss";

$gap: 10px;
$top: 40%;
$padding-40: 40px;

.no-data-found {
  font-family: $font-family-book;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $gap-1/2;
  flex: 1 0 0;
  align-self: stretch;
  color: $gray-light;
  font-size: 0.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.625rem; /* 125% */
  letter-spacing: 0.005rem;
}

//Media query
@media (max-width: $width-319) and (orientation: portrait) {
  .no-data-found {
    font-size: 0.4rem;
    line-height: 0.5rem;
    letter-spacing: 0.004rem;
  }
}
@media (min-width: $width-641) and (max-width: $width-960) and (orientation: portrait) {
  .no-data-found {
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: 0.01rem;
  }
}
@media (min-width: $width-961) and (max-width: $width-1023) and (orientation: portrait) {
  .no-data-found {
    font-size: 0.6rem;
    letter-spacing: 0.006rem;
    line-height: 0.75rem;
  }
}
@media (min-width: $width-1024) and (min-height: $width-1281) {
  .no-data-found {
    font-size: 1rem;
    line-height: 1.25rem;
    letter-spacing: 0.01rem;
  }
}
@media (min-width: $width-1025) and (max-width: $width-1281) {
  .no-data-found {
    font-size: 0.7rem;
    letter-spacing: 0.007rem;
    line-height: 0.87rem;
  }
}

//landscape
@media (min-width: $width-641) and (max-width: $width-960) and (orientation: landscape) {
  .no-data-found {
    font-size: 0.6rem;
    letter-spacing: 0.006rem;
    line-height: 0.75rem;
  }
}
