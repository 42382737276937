@import "../../assets/styles/variables.scss";

$padding-top: 30px;
$padding-right: 40px;
$padding-bottom: 40px;
$padding-left: 40px;
$gap: 20px;
$top: 40%;
$px50: 50px;

.error-message {
  font-family: $font-family-book;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $gap-1/3;
  flex: 1 0 0;
  align-self: stretch;
  color: $white;
  font-size: 0.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.625rem; /* 125% */
  letter-spacing: 0.005rem;
}

.error-logo {
  width: 1.8rem;
  height: 1.8rem;
}

//Media query
@media (max-width: $width-319) and (orientation: portrait) {
  .error-message {
    font-size: 0.5rem;
    line-height: 0.62rem;
    letter-spacing: 0.005rem;
  }
  .error-logo {
    width: 1.5rem;
    height: 1.5rem;
  }
}
@media (min-width: $width-641) and (max-width: $width-960) and (orientation: portrait) {
  .error-message {
    font-size: 1rem;
    line-height: 1.25rem; /* 125% */
    letter-spacing: 0.01rem;
    gap: 0.2rem;
  }
  .error-logo {
    width: 3rem;
    height: 3rem;
  }
}
@media (min-width: $width-961) and (max-width: $width-1023) and (orientation: portrait) {
  .error-message {
    font-size: 0.6rem;
    line-height: 0.75rem; /* 125% */
    letter-spacing: 0.006rem;
    gap: 0.2rem;
  }
  .error-logo {
    width: 1.8rem;
    height: 1.8rem;
  }
}
@media (min-width: $width-1024) and (min-height: $width-1281) {
  .error-message {
    font-size: 1rem;
    line-height: 1.25rem; /* 125% */
    letter-spacing: 0.01rem;
  }
  .error-logo {
    width: 3rem;
    height: 3rem;
  }
}
@media (min-width: $width-1025) and (max-width: $width-1281) {
  .error-message {
    font-size: 0.8rem;
    line-height: 1rem; /* 125% */
    letter-spacing: 0.008rem;
  }
  .error-logo {
    width: 2.4rem;
    height: 2.4rem;
  }
}
//landscape
@media (min-width: $width-641) and (max-width: $width-960) and (orientation: landscape) {
  .no-data-found {
    font-size: 0.6rem;
    letter-spacing: 0.006rem;
    line-height: 0.75rem;
  }
}
