@import "./assets/styles/variables.scss";

@font-face {
  font-family: "Gotham-black";
  src: local("Gotham-black"),
    url("./assets/fonts/gotham-black-webfont.woff2") format("woff2");
}

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("./assets/fonts/gotham-bold-webfont.woff2") format("woff2");
}

@font-face {
  font-family: "Gotham-book";
  src: local("Gotham-book"),
    url("./assets/fonts/gotham-book-webfont.woff2") format("woff2");
}

html {
  text-align: center;
  font-family: $font-family;
  font-size: $font-size;
  font-style: normal;
  font-weight: $font-weight;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
  width: 100%;
  height: 100%;
  max-width: 700px;
  margin: auto;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $gray-dark;
  height: 100%;
}

#root {
  height: 100%;
}
