@import "../../assets/styles/variables.scss";

$box_height: 100%;
$padding-32: 1rem;
$padding-16: 0.5rem;
$px40: 40px;
$px16: 16px;
$letter-spacing-0-16: 0.16px;
$line-height-24: 24px;
$font-weight-400: 400;
$font-weight-900: 900;
$gap-16: 0.5rem;
$border-bottom-1: 1px solid $black-light;
$border-bottom-2: 2px solid $black-light;

// container
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app-heading {
  background-color: $gray-dark;
  display: flex;
  padding: $padding-1/2 $padding-1;
  align-items: center;
  gap: $gap-1/2;
  align-self: stretch;
  border-bottom: $border-bottom-1;
  color: $white;
  font-size: $font-size/2;
  line-height: normal;
  letter-spacing: $letter-spacing/2;
  position: sticky;
  top: 0;
}

.logo {
  width: 1.5rem;
  height: 1.5rem;
}

.inner-container {
  flex: 1;
  display: flex;
}

.track-container {
  width: 100%;
  display: flex;
  padding: $padding-1/2 $padding-1 $padding-1/2 $padding-1;
  flex-direction: column;
  align-items: flex-start;
  // gap: 2.5rem;
  gap: $gap-1;
  align-self: stretch;
}

.track-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: $gap-1/2;
  align-self: stretch;
}

.track-name {
  color: $gray-light;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.004rem;
  text-transform: uppercase;
}

.inner-card {
  display: flex;
  height: 3rem;
  padding: 0.6rem;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 0.25rem;
  background: $black-light;
}

.inner-card-pagar {
  @extend .inner-card;
  border: 2px solid $red;
}

.race-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.09rem;
  font-family: $font-family-bold;
  text-align: start;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.008rem;
}

.display-content-flex {
  display: flex;
  flex-direction: column;
}

.race-info-type-pagar {
  color: $red;
}

.race-info-type {
  color: $blue;
}

.race-info-num {
  color: $pink;
}


.race-kmtime-num {
  flex: 0 1 15%;
  color: $white;
  font-size: x-small;
  letter-spacing: inherit;
  font-weight: 200;
  font-family: Gotham-black;
  line-height: initial !important;
  text-align: center;
}

.event-type-start {
  align-items: center !important;
  display: flex !important;
  flex-direction: row !important;
  margin-left: auto !important;
}
.event-type-start img {
  margin-left: 0 !important; /* Override any unwanted margin */
  order: 1; /* Ensure it's first in the flex order */
}
.event-type-restart img {
  margin-left: auto !important; /* Override any unwanted margin */
  order: 1; /* Ensure it's first in the flex order */
}

.logo-display {
  width: auto;
  height: 2.7rem;
}

.event-type-restart {
  @extend .event-type-start;
}

.event-type-time {
  font-size: 3rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.15rem;
  padding: 0rem;
}

.event-type-split-time {
  @extend .event-type-time;
  color: $white;
}

.event-type-time-start {
  @extend .event-type-time;
  color: $yellow;
}

.event-type-time-pagar {
  @extend .event-type-time;
  color: $red;
}

.event-type-time-def {
  @extend .event-type-time;
  color: $green;
}

.event-type-finish {
  flex: 0 1 85%;
  font-size: 1.8rem;
  line-height: 2.2rem !important;
  font-family: 'Gotham-black';
  text-align: center;
  letter-spacing: 0.2rem;
  color: $blue;
}

.event-type-finish-img {
  @extend .event-type-start;
  color: $blue;
}


.footer {
  display: flex;
  padding: $padding-1/2 $padding-1;
  align-items: center;
  bottom: 0;
  flex-direction: column;
}

.footer-text {
  color: $gray;
  font-size: 0.4em;
  line-height: 0.6rem;
  /* 150% */
  letter-spacing: 0.004rem;
  font-family: $font-family-book;
  font-weight: 400;
  flex-shrink: 0;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $black-light;
}


//Media query
@media (max-width: $width-319) and (orientation: portrait) {
  .app-heading {
    padding: 0.25rem 0.5rem;
    gap: 0.25rem;
    font-size: 0.6rem;
  }

  .logo {
    width: 0.9rem;
    height: 0.9rem;
  }

  .inner-container {
    gap: 2rem;
  }

  .footer {
    padding: 0.25rem 0.5rem;
  }

  .footer-text {
    font-size: 0.3rem;
    line-height: 0.45rem;
    /* 150% */
    letter-spacing: 0.003rem;
  }

  .track-container {
    gap: 0.5rem;
    padding: 0.25rem 0.5rem;
  }

  .track-name {
    font-size: 0.35rem;
  }

  .track-info {
    gap: 0.3rem;
  }

  .inner-card {
    height: 1rem;
    padding: 0.5rem;
  }

  .inner-card-pagar {
    height: 1rem;
    padding: 0.5rem;
  }

  .race-info {
    // gap: 0.01rem;
    font-size: 0.3rem;
    letter-spacing: 0.003rem;
  }

  .event-type-time-start {
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
  }

  .event-type-time-pagar {
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
  }

  .event-type-time-def {
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #1c1c1c;
  }

  .event-type-split-time {
    font-size: 1.2rem;
    letter-spacing: 0.05rem;
  }

  .race-kmtime-num {
    font-size: xx-small;
    /* Adjusted size for small screens */
    line-height: initial !important;
  }

  .event-type-finish {
    font-size: 1.4rem;
    letter-spacing: 0.05rem;
  }

  .event-type-restart img {
    margin-left: auto !important;
    height: 2rem !important;
  }
}

@media (min-width: $width-320) and (max-width: $width-480) and (orientation: portrait) {

  /* smartphones, iPhone, portrait 480x320 phones */
  .app-heading {
    padding: $padding-1/2.5 $padding-1/1.2;
    gap: $gap-1/2.5;
    font-size: 0.8rem;
  }

  .logo {
    width: 1.2rem;
    height: 1.2rem;
  }

  .track-container {
    gap: $gap-1;
    padding: $padding-1/2.5 $padding-1/1.2;
  }

  .footer {
    padding: $padding-1/2.5 $padding-1/1.2;
  }

  .footer-text {
    font-size: 0.35rem;
    line-height: 0.525rem;
    /* 150% */
    letter-spacing: 0.0035rem;
  }

  .track-name {
    font-size: 0.5rem;
    font-weight: 700;
    letter-spacing: 0.003rem;
  }

  .inner-card {
    height: 1.5rem;
    padding: 0.7rem;
  }

  .inner-card-pagar {
    height: 1.5rem;
    padding: 0.7rem;
  }

  .race-info {
    gap: 0.01rem;
    font-size: 0.5rem;
    letter-spacing: 0.005rem;
  }

  .event-type-time-start {
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
  }

  .event-type-time-pagar {
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
  }

  .event-type-time-def {
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
  }

  .event-type-split-time {
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
  }

  .race-kmtime-num {
    font-size: x-small !important;
  }

  .event-type-finish {
    font-size: 1.6rem !important;
    line-height: 1.8rem !important;
  }
}

@media (min-width: $width-481) and (max-width: $width-640) and (orientation: portrait) {

  //   /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
  .app-heading {
    padding: 0.5rem 1rem;
    gap: 0.4rem;
    font-size: 0.8rem;
  }

  .logo {
    width: 1.2rem;
    height: 1.2rem;
  }

  .footer {
    padding: 0.5rem 1rem;
  }

  .footer-text {
    font-size: 0.35rem;
    line-height: 0.525rem;
    /* 150% */
    letter-spacing: 0.0035rem;
  }

  .track-container {
    gap: 1rem;
    padding: 0.5rem 1rem;
  }

  .track-name {
    font-size: 0.5rem;
  }

  .track-info {
    gap: 0.4rem;
  }

  .inner-card {
    height: 1.4rem;
    padding: 0.7rem;
  }

  .inner-card-pagar {
    height: 1.4rem;
    padding: 0.7rem;
  }

  .race-info {
    gap: 0.01rem;
    font-size: 0.5rem;
    letter-spacing: 0.005rem;
  }

  .event-type-time-start {
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
  }

  .event-type-time-pagar {
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
  }

  .event-type-time-def {
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
  }

  .event-type-split-time {
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
  }

  .race-kmtime-num {
    font-size: x-small !important;
  }

  .event-type-finish {
    font-size: 1.6rem !important;
    line-height: 1.8rem !important;
  }
}

@media (min-width: $width-641) and (max-width: $width-960) and (orientation: portrait) {

  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .app-heading {
    padding: 1rem 2rem;
    gap: 0.7rem;
    font-size: 1.5rem;
  }

  .logo {
    width: 2rem;
    height: 2rem;
  }

  .footer {
    padding: 1rem 2rem;
  }

  .footer-text {
    font-size: 0.6rem;
    line-height: 0.9rem;
    /* 150% */
    letter-spacing: 0.006rem;
  }

  .track-container {
    padding: 1rem 2rem;
  }

  .race-kmtime-num {
    font-size: x-small !important;
  }

  .event-type-finish {
    font-size: 1.8rem !important;
    line-height: 1.6rem !important;
  }
}

@media (min-width: $width-961) and (max-width: $width-1023) and (orientation: portrait) {

  /* tablet, landscape iPad, lo-res laptops ands desktops */
  .app-heading {
    padding: 0.4rem 0.8rem;
    gap: 0.4rem;
    font-size: 0.8rem;
  }

  .logo {
    width: 1.5rem;
    height: 1.5rem;
  }

  .footer {
    padding: 0.4rem 0.8rem;
  }

  .track-container {
    gap: 1rem;
    padding: 0.4rem 0.8rem;
  }

  .track-name {
    font-size: 0.5rem;
  }

  .track-info {
    gap: 0.4rem;
  }

  .inner-card {
    height: 1.4rem;
    padding: 0.7rem;
  }

  .inner-card-pagar {
    height: 1.4rem;
    padding: 0.7rem;
  }

  .race-info {
    gap: 0.01rem;
    font-size: 0.5rem;
    letter-spacing: 0.005rem;
  }

  .event-type-time-start {
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
  }

  .event-type-time-pagar {
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
  }

  .event-type-time-def {
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
  }
}

@media (min-width: $width-1024) and (min-height: $width-1281) {
  .app-heading {
    padding: 0.8rem 1.6rem;
    gap: 0.8rem;
    font-size: 1.6rem;
  }

  .logo {
    width: 2.5rem;
    height: 2.5rem;
  }

  .footer {
    padding: 0.8rem 1.6rem;
  }

  .footer-text {
    font-size: 0.7rem;
    line-height: 1.05rem;
    /* 150% */
    letter-spacing: 0.007rem;
  }

  .track-container {
    gap: 0.8rem;
    padding: 0.8rem 1.6rem;
  }

  .track-name {
    font-size: 0.5rem;
  }

  .track-info {
    gap: 0.5rem;
  }

  .inner-card {
    height: 1.6rem;
    padding: 0.8rem;
  }

  .inner-card-pagar {
    height: 1.6rem;
    padding: 0.8rem;
  }

  .race-info {
    gap: 0.05rem;
    font-size: 0.7rem;
    letter-spacing: 0.007rem;
  }

  .event-type-time-start {
    font-size: 2.5rem;
    letter-spacing: 0.2rem;
  }

  .event-type-time-pagar {
    font-size: 2.5rem;
    letter-spacing: 0.2rem;
  }

  .event-type-time-def {
    font-size: 2.5rem;
    letter-spacing: 0.2rem;
  }

  .event-type-split-time {
    font-size: 2.5rem;
    letter-spacing: 0.2rem;
  }
}

@media (min-width: $width-1024) and (max-width: $width-1281) {

  /* big landscape tablets, laptops, and desktops */
  .app-heading {
    padding: 0.6rem 1.2rem;
    gap: 0.6rem;
    font-size: 1.2rem;
  }

  .logo {
    width: 1.7rem;
    height: 1.7rem;
  }

  .footer {
    padding: 0.6rem 1.2rem;
  }

  .track-container {
    gap: 1rem;
    padding: 0.6rem 1.2rem;
  }

  .track-name {
    font-size: 0.5rem;
  }

  .track-info {
    gap: 0.5rem;
  }

  .inner-card {
    height: 1.6rem;
    padding: 0.8rem;
  }

  .inner-card-pagar {
    height: 1.6rem;
    padding: 0.8rem;
  }

  .race-info {
    gap: 0.05rem;
    font-size: 0.7rem;
    letter-spacing: 0.007rem;
  }

  .event-type-time-start {
    font-size: 2.5rem;
    letter-spacing: 0.2rem;

  }

  .event-type-time-pagar {
    font-size: 2.5rem;
    letter-spacing: 0.2rem;
  }

  .event-type-time-def {
    font-size: 2.5rem;
    letter-spacing: 0.2rem;
  }

  .event-type-split-time {
    font-size: 2.5rem;
    letter-spacing: 0.2rem;
  }
}

@media (min-width: 1281px) {

  /* hi-res laptops and desktops */
  .container {
    left: 300px;
    right: 300px;
  }

  .track-name {
    font-size: 0.5rem;
  }

  .track-info {
    gap: 0.5rem;
  }

  .inner-card {
    height: 1.6rem;
    padding: 0.8rem;
  }

  .inner-card-pagar {
    height: 1.6rem;
    padding: 0.8rem;
  }

  .race-info {
    gap: 0.05rem;
    font-size: 0.7rem;
    letter-spacing: 0.007rem;
  }

  .event-type-time-start {
    font-size: 2.5rem;
    letter-spacing: 0.2rem;
  }

  .event-type-time-pagar {
    font-size: 2.5rem;
    letter-spacing: 0.2rem;
  }

  .event-type-time-def {
    font-size: 2.5rem;
    letter-spacing: 0.2rem;
  }
}

//landscape
@media (min-width: $width-641) and (max-width: $width-960) and (orientation: landscape) {

  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  .app-heading {
    padding: 0.5rem 1rem;
    gap: 0.35rem;
    font-size: 0.8rem;
  }

  .logo {
    width: 1rem;
    height: 1rem;
  }

  .footer {
    padding: 0.5rem 1rem;
  }

  .footer-text {
    font-size: 0.3rem;
    line-height: 0.5rem;
    /* 150% */
    letter-spacing: 0.003rem;
  }

  .track-container {
    padding: 0.5rem 1rem;
  }

  .track-name {
    font-size: 0.5rem;
  }

  .track-info {
    gap: 0.4rem;
  }

  .inner-card {
    height: 1.4rem;
    padding: 0.7rem;
  }

  .inner-card-pagar {
    height: 1.4rem;
    padding: 0.7rem;
  }

  .race-info {
    gap: 0.01rem;
    font-size: 0.5rem;
    letter-spacing: 0.005rem;
  }

  .event-type-time-start {
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
  }

  .event-type-time-pagar {
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
  }

  .event-type-time-def {
    font-size: 1.8rem;
    letter-spacing: 0.09rem;
  }
}